import React, {useContext} from "react";
import {NavLink} from "react-router-dom";
import {AuthContext} from "../../../context/auth-context";
import { greekLabels } from "../../../util/language";
import "./NavLinks.css";

const NavLinks = () => {
  const auth = useContext(AuthContext);

  let activeStyle = {
    color: "white",
  };

  return (
    <ul className='nav-links'>
      {auth.isLoggedIn && (
        <li>
          <NavLink to={"/trees"}
            style={({ isActive }) =>
              isActive ? activeStyle : undefined
            }
          >
            <span className="mobile-nav-view">{greekLabels.MY_TREES_LOWER}</span><i className="bi bi-tree-fill"></i>
          </NavLink>
        </li>
      )}

      {(auth.isLoggedIn && auth.roles && auth.roles.includes("admin")) && (
        <span className="mobile-nav-view">
          <li>
            <NavLink to={"/reports"}
              style={({ isActive }) =>
                isActive ? activeStyle : undefined
              }
            >
              {greekLabels.REPORTS} <i className="bi bi-file-earmark-text-fill"></i>
            </NavLink>
          </li>
        </span>
      )}
      
      {(auth.isLoggedIn && auth.roles && auth.roles.includes("admin")) && (
        <span className="mobile-nav-view">
          <li>
            <NavLink to={"/accounts-manager"}
              style={({ isActive }) =>
                isActive ? activeStyle : undefined
              }
            >
              {greekLabels.USER_MANAGEMENT} <i className="bi bi-people-fill"></i>
            </NavLink>
          </li>
        </span>
      )}
      {auth.isLoggedIn && (
        <li>
          <NavLink to={"/map"}
            style={({ isActive }) =>
              isActive ? activeStyle : undefined
            }
          >
            <span className="mobile-nav-view">{greekLabels.TREE_MAP}</span> <i className="bi bi-map-fill"></i>
          </NavLink>
        </li>
      )}
      <span className="mobile-nav-view">
        <li>
          <NavLink to={"/legal"}
            style={({ isActive }) =>
              isActive ? activeStyle : undefined
            }
          >
            {greekLabels.LEGALITY} <i className="bi bi-bank2"></i>
          </NavLink>
        </li>
      </span>

      <span className="mobile-nav-view">
        <li>
          <NavLink to={"/contact"}
            style={({ isActive }) =>
              isActive ? activeStyle : undefined
            }
          >
            {greekLabels.CONTACT_US} <i className="bi bi-telephone-fill"></i>
          </NavLink>
        </li>
      </span>

      {(auth.isLoggedIn && auth.roles && auth.roles.includes("admin")) && (
        <span className="pc-nav-view"> 
          <li>
            <NavLink to={"/admin-panel"}
              style={({ isActive }) =>
                isActive ? activeStyle : undefined
              }
            >
              <i className="bi bi-shield-lock-fill"></i>
            </NavLink>
          </li>
        </span>
      )}

      <span className="mobile-nav-view">
        <li>
          <NavLink to={"/developers"}
            style={({ isActive }) =>
              isActive ? activeStyle : undefined
            }
          >
            {greekLabels.DEVELOPERS} <i className="bi bi-file-person"></i>
          </NavLink>
        </li>
      </span>

      {auth.isLoggedIn && (
        <li>
          <NavLink to={"/settings"}
            style={({ isActive }) =>
              isActive ? activeStyle : undefined
            }
          >
            <span className="mobile-nav-view">{greekLabels.SETTINGS} </span><i className="bi bi-gear-fill"></i>
          </NavLink>
        </li>
      )}
      
      {/* Login / Logout */}
      {!auth.isLoggedIn && (
        <li>
          <NavLink to={"/auth"}
            style={({ isActive }) =>
              isActive ? activeStyle : undefined
            }
          >
            <span className="mobile-nav-view">{greekLabels.LOGIN} </span><i className="bi bi-box-arrow-in-right"></i>
          </NavLink>
        </li>
      )}
      {auth.isLoggedIn && (
        <span className="mobile-nav-view">
          <li>
            <NavLink to={"/auth"}>
              <button onClick={auth.logout}>{greekLabels.DISCONNECT} <i className="bi bi-door-closed-fill"></i></button>
            </NavLink>
          </li>
        </span>
      )}
    </ul>
  );
};

export default NavLinks;
