import ReportItem from "../ReportItem/ReportItem";
import {Col, Container, Row} from "react-grid-system";
import "./ReportList.css"
import { greekLabels } from "../../../util/language";

const ReportList = (props) => {

  // If there are no reports found, render a call to action.
  if (props.items.length === 0) {
    return (
      <div className='center'>
        <h2>{greekLabels.NO_USER_REPORTS}</h2>
      </div>
    );
  }
  // Render the report list.
  else {
    return (
      <div className={'report-list'}>
        <ul>
          <Container>
            <Row>
              {props.items.map((report) => (
                <Col sm={12} lg={6} xl={4} key={report.id}>
                  <ReportItem
                    id={report.id}
                    title={report.title}
                    creatorId={report.creatorId}
                    treeId={report.treeId}
                    adminId={report.adminId}
                    isResolved={report.isResolved}
                    description={report.description}
                    creationDate={report.creationDate}
                    onAssignReport={props.onAssignReport}
                    onResolveReport={props.onResolveReport}
                  />
                </Col>
              ))}
            </Row>
          </Container>
        </ul>
      </div>
    );
  }
};

export default ReportList;
