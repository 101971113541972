import React from 'react';
import {Col, Container, Row} from 'react-grid-system';
import "./Legal.css";
import Card from "../CustomUIElements/Card/Card";
import { greekLabels } from '../../util/language';

export function Legal() {
  return <React.Fragment>
    <Container className="legal-page">
      <Row>
        <Col md={12}>
          {/* Information Area */}
          <h1 style={{textAlign: "center", color: "green", fontWeight: "bold"}}>
            {greekLabels.LEGALITY_DATA} <i className="bi bi-bank2"></i>
          </h1>
          <Card style={{marginBottom: "20px"}}>
            <p>{greekLabels.LEGALITY_PARAGRAPH}<br/><br/>{greekLabels.SELECT_ICON_FOR_MORE_INFO}</p>
          </Card>
        </Col>
        {/* Link */}
        <Col md={6} style={{textAlign: "center"}} className="icons">
          {/* Update the link below if you switch Google Play Project (re-deploy from the start) */}
          <a href="https://docs.google.com/document/u/1/d/e/2PACX-1vS2dsBMgAKUvQx0UmHmO0jzaSizzxcZMNIxMSyZTRjFiVGn6STXENalRKI2_Dd4EeXEB45-pXAXlm1N/pub" style={{textDecoration: "none", color: "inherit"}}>
            <Card>
              <i className="bi  bi-file-earmark-text" style={{fontSize: "100px"}}></i>
              <br/>
              <p style={{textAlign: "center"}}>{greekLabels.PRIVACY_POLICY}</p>
            </Card>
          </a>
        </Col>
      </Row>
    </Container>
  </React.Fragment>
}
