import React, {useContext, useEffect, useState} from "react";
import ReportList from "../ReportList/ReportList";
import ErrorModal from "../../CustomUIElements/Modals/ErrorModal";
import LoadingSpinner from "../../CustomUIElements/LoadingSpinner/LoadingSpinner";
import {useHttpClient} from "../../../hooks/http-hook";
import {AuthContext} from "../../../context/auth-context";
import Toast from "../../CustomUIElements/Toast/Toast"
import './UserReports.css';
import { greekLabels } from "../../../util/language";

const UserReports = () => {
  // Stateful variables
  const [loadedReports, setLoadedReports] = useState();
  const {isLoading, error, sendRequest, clearError} = useHttpClient();
  const auth = useContext(AuthContext);
  //Notification (Toast)
  const notificationRef = React.createRef()
  const [notification, setNotification] = React.useState({})

  // Notifications Runner
  useEffect(() => {
    if (notification) {
      notificationRef.current.toast()
    }
  }, [notification]);

  // Fetch my reports (propagates props to Report item and list)
  const fetchReports = async () => {
    try {
      let url = process.env.REACT_APP_BACKEND_URL + `/reports`
     await sendRequest(
          url,
          "GET",
          null,
          {
            "Content-Type": "application/json",
            "Authorization": "JWT " + auth.token
          }
        ).then(responseData => {
        setLoadedReports(responseData.data);
        })

    } catch (err) {
      console.log("Exception thrown in fetch reports: " + err);
    }
  };

  useEffect(() => {
    fetchReports();
  }, []);

  // Assign a report method
  const ReportAssignedHandler = async (reportId) => {
    let url = process.env.REACT_APP_BACKEND_URL + `/reports/toggleActive`
    try {
      //Send request to resolve a report
      await sendRequest(
        url,
        "PATCH",
        JSON.stringify({
          rid: reportId,
          uid: auth.userId
        }),
        {
          "Content-Type": "application/json",
          "Authorization": "JWT " + auth.token
        }
      ).then(() => {
        //Update the reports admin
        setLoadedReports(loadedReports.map(report => {
          // Find report with this id and update the admin to be myself
          if (report.id === reportId) {
            if (report.adminId)
              report.adminId = null
            else
              report.adminId = auth.userId;
          }
          return report;
        }))

        //Success Notification
        setNotification({type: "success", message: greekLabels.REPORT_STATUS_UPDATED})
      })
    } catch (error) {
      //Error
      setNotification({type: "error", message: greekLabels.NO_REPORT_STATUS_CHANGE})
    }
  };

  // Resolve a report method
  const ReportResolvedHandler = async (id) => {
    let url = process.env.REACT_APP_BACKEND_URL + `/reports/${id}`
    try {
      //Send request to resolve a report
      await sendRequest(
        url,
        "PATCH",
        null,
        {
          "Content-Type": "application/json",
          "Authorization": "JWT " + auth.token
        }
      ).then(() => {
        //Update report status
        setLoadedReports(loadedReports.map(report => {
          if (report.id === id) {
            report.isResolved = !report.isResolved;
          }
          return report;
        }))

        //Success Notification
        setNotification({type: "success", message: greekLabels.REPORT_RESOLVED})
      })
    } catch (error) {
      //Error
      setNotification({type: "error", message: greekLabels.NO_REPORT_RESOLUTION_CHANGE})
    }
  }

  return (
    <React.Fragment>
      {/* Loading */}
      <ErrorModal error={error} onClear={clearError}/>
      {isLoading && (
        <div className='center'>
          <LoadingSpinner/>
        </div>
      )}
      {/* Renders a Report List */}
      {!isLoading && loadedReports && (
        <div className={"user-reports"}>
          <h1>{greekLabels.TREE_REFERENCE_TABLE} <i className="bi bi-file-earmark-text-fill"></i></h1>
          <ReportList
            items={loadedReports}
            onAssignReport={ReportAssignedHandler}
            onResolveReport={ReportResolvedHandler}
          />
        </div>
      )}
      {/* Notifications Runner */}
      <Toast ref={notificationRef} type={notification.type} message={notification.message}></Toast>
    </React.Fragment>
  );
};

export default UserReports;
