import React, {useContext, useEffect, useState} from 'react';
import './AccountsManager.css';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import {
  Grid,
  Button,
  CardContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from "@mui/material";
import Card from "../CustomUIElements/Card/Card";
import {DataGrid} from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';
import CheckIcon from '@mui/icons-material/Check';
import {useHttpClient} from "../../hooks/http-hook";
import {AuthContext} from "../../context/auth-context";
import {User} from "../../models/User";
import ConfirmationDialogBox from "../CustomUIElements/ConfirmationDialogBox/ConfirmationDialogBox";
import { greekLabels } from '../../util/language';

const AccountsManager = props => {
  //Attributes
  const {isLoading, error, sendRequest, clearError} = useHttpClient()
  const auth = useContext(AuthContext);

  //Data
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [userPagination, setUserPagination] = useState({total: 0, page: 0, pages: 1});

  //Form Data
  const [userForm, setUserForm] = useState({email: ""});
  const [userRolesForm, setUserRolesForm] = useState({admin: false});
  const [userBlockForm, setUserBlockForm] = useState({block: false});

  //Alerts
  const [roleAlert, setRoleAlert] = useState({isVisible: false, message: "", severity: "success"});
  const [blockAlert, setBlockAlert] = useState({isVisible: false, message: "", severity: "success"});

  //Dialogs
  const [dialogIsVisible, setDialogIsVisible] = useState(false)
  const [confirmationDialog, setConfirmationDialog] = useState({
    title: "",
    description: "",
    onAgree: null,
    onDisagree: null
  });

  //Table
  const [userTableIsLoading, setUserTableIsLoading] = useState(false)
  const columns = [
    {field: 'email', headerName: 'Email', flex: 0.4},
    {field: 'roles', headerName: greekLabels.USERS_ROLES, flex: 0.4},
    {field: 'isBlocked', headerName: greekLabels.BLOCKED, flex: 0.2},
  ];

  //Default user searching filters
  const limit = 10

  //Hooks
  useEffect(() => {
    //Retrieve initial set of user data
    updateUserTable(limit, 0, userForm.email)
  }, [])

  //Handle user form input changes
  const handleUserInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setUserForm({
      [name]: value
    });
  }

  //Handle user roles form input changes
  const handleUserRolesInputChange = (event) => {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    //Boolean conversion
    if (value === "true") {
      value = true
    } else if (value === "false") {
      value = false
    }

    setUserRolesForm({
      [name]: value
    });
  }

  //Handle user block form input changes
  const handleUserBlockInputChange = (event) => {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    //Boolean conversion
    if (value === "true") {
      value = true
    } else if (value === "false") {
      value = false
    }

    setUserBlockForm({
      [name]: value
    });
  }

  //Methods
  //Function updates user table based on limit, skip values and user email
  const updateUserTable = async (limit, skip, email) => {
    //Empty users data
    setUsers([])

    //Start loading animation
    setUserTableIsLoading(true)

    try {
      //Send request to retrieve a paginated result of users
      let url = process.env.REACT_APP_BACKEND_URL + `/users/?limit=${encodeURIComponent(limit)}&skip=${encodeURIComponent(skip)}&email=${encodeURIComponent(email)}`
      await sendRequest(
        url,
        "GET",
        null,
        {
          "Content-Type": "application/json",
          "Authorization": "JWT " + auth.token
        }
      ).then(response => {
        //Set user state
        setUsers(response.data.map((user) => {
          //Map data results to User objects
          return new User(user.id, user.email, user.trees, user.roles, user.isBlocked)
        }))

        //Update pagination values
        setUserPagination({
          total: response.paging.total,
          pages: response.paging.total,
          page: response.paging.page - 1
        })

        //Remove loading animation
        setUserTableIsLoading(false)
      })
    } catch (err) {
      //Error
      //Remove loading animation
      setUserTableIsLoading(false)
      setRoleAlert({isVisible: true, message: greekLabels.COULD_NOT_LOAD_USER_DATA, severity: "error"})
    }
  };
  //User Search Form submission code
  const searchUsers = (event) => {
    //Update user table
    event.preventDefault();
    updateUserTable(limit, 0, userForm.email)
  }

  //Role Update Form submission code
  const updateUserRoles = (event) => {
    event.preventDefault();
    //Show confirmation dialog
    setConfirmationDialog({
      title: greekLabels.INFORMATION_FOR_USER_ROLE,
      description: greekLabels.UPDATE_SELECTED_USER_ROLES,
      onAgree: function () {
        //Check if user is attempting to modify roles for themselves
        if (!selectedUsers.some(user => String(user).localeCompare(auth.userId) === 0)) {
          //Update each selected user with new role
          selectedUsers.forEach((userId) => {
            updateUserRole(userId, userRolesForm.admin)
          })
        } else {
          //Error
          setRoleAlert({
            isVisible: true,
            message: greekLabels.CANNOT_MODIFY_OWN_ROLE,
            severity: "error"
          })
        }

        //Update user table after delay
        setTimeout(() => {
          updateUserTable(limit, limit * userPagination.page, userForm.email)
        }, 3000)
      },
      onDisagree: function () {
      }
    })

    setDialogIsVisible(true)
  }

  //Function updates user roles based on user ID and boolean for being admin
  const updateUserRole = async (uid, isAdmin) => {
    //Reset Alerts
    setRoleAlert({isVisible: false, message: "", severity: ""})

    let url
    //Determine endpoint for removal or addition of admin role
    if (isAdmin) {
      url = process.env.REACT_APP_BACKEND_URL + `/admins/addAdminPrivileges`
    } else {
      url = process.env.REACT_APP_BACKEND_URL + `/admins/removeAdminPrivileges`
    }

    try {
      //Send request to update user role
      await sendRequest(
        url,
        "PATCH",
        JSON.stringify({
          userId: uid
        }),
        {
          "Content-Type": "application/json",
          "Authorization": "JWT " + auth.token
        }
      ).then(response => {
        setRoleAlert({isVisible: true, message: greekLabels.OPERATION_COMPLETED, severity: "success"})
      })
    } catch (err) {
      //Error
      setRoleAlert({isVisible: true, message: greekLabels.OPERATION_FAILED, severity: "error"})
    }
  }

  //Function handles form submission for blocking users
  const updateUsersBlockStatus = (event) => {
    event.preventDefault()
    //Show confirmation dialog
    setConfirmationDialog({
      title: greekLabels.UNBLOCK_USER,
      description: greekLabels.SURE_FOR_UNBLOCKING_USERS,
      onAgree: function () {
        //Check if user is attempting to block themselves
        if (!selectedUsers.some(user => String(user).localeCompare(auth.userId) === 0)) {
          //Update each selected user with new block status
          selectedUsers.forEach((userId) => {
            updateUserBlock(userId, userBlockForm.block)
          })
        } else {
          //Error
          setBlockAlert({isVisible: true, message: greekLabels.CANNOT_UNBLOCK_YOURSELF, severity: "error"})
        }

        //Update user table after delay
        setTimeout(() => {
          updateUserTable(limit, limit * userPagination.page, userForm.email)
        }, 3000)
      },
      onDisagree: function () {
      }
    })

    setDialogIsVisible(true)
  }

  //Function block or unblocks a user based on user ID and boolean
  const updateUserBlock = async (uid, isBlocked) => {
    //Reset Alerts
    setBlockAlert({isVisible: false, message: "", severity: ""})

    let url
    //Determine endpoint for removal or addition of admin role
    if (isBlocked) {
      url = process.env.REACT_APP_BACKEND_URL + `/admins/blockUser`
    } else {
      url = process.env.REACT_APP_BACKEND_URL + `/admins/unblockUser`
    }

    try {
      //Send request to update user block status
      await sendRequest(
        url,
        "PATCH",
        JSON.stringify({
          userId: uid
        }),
        {
          "Content-Type": "application/json",
          "Authorization": "JWT " + auth.token
        }
      ).then(response => {
        setBlockAlert({isVisible: true, message: greekLabels.OPERATION_COMPLETED, severity: "success"})
      })
    } catch (err) {
      //Error
      setBlockAlert({isVisible: true, message: greekLabels.OPERATION_FAILED, severity: "error"})
    }
  }

  return (
    <div className="AccountsManager">
      <h1>{greekLabels.USER_MANAGEMENT}<i className="bi bi-people-fill"></i></h1>
      <Container maxWidth={"lg"}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>

            {/*Users Input Form*/}
            <form onSubmit={searchUsers}>
              <Box sx={{display: 'flex'}}>
                <TextField sx={{mb: 2, mr: 2, flexGrow: 1}} id="email-input" name="email" size="small"
                           label={greekLabels.SEARCH_FOR_USER} variant="filled" onChange={handleUserInputChange}/>
                <Button sx={{mb: 2, mr: 2}} size="large" variant="contained" type="Submit"
                        endIcon={<SearchIcon/>}>{greekLabels.SEARCH}</Button>
              </Box>
            </form>

            {/*Users Table*/}
            <DataGrid
              title={greekLabels.USER_TABLE}
              rows={users}
              columns={columns}
              autoHeight
              pagination
              checkboxSelection
              onSelectionModelChange={(ids) => {
                setSelectedUsers(ids)
              }}
              loading={userTableIsLoading}
              paginationMode="server"
              rowCount={userPagination.pages}
              pageSize={limit}
              rowsPerPageOptions={[limit]}
              onPageChange={(page) => updateUserTable(limit, limit * page, userForm.email)}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <Box sx={{mb: 3}}>
              {/*Roles Update Card*/}
              <Card>
                <CardContent>
                  <Typography variant="h5">
                    {greekLabels.CONVERT_USER_ROLE}
                  </Typography>
                  <Typography variant="body2" sx={{mt: 2}}>
                    {greekLabels.UPDATE_USER_ROLE_MSG}  
                  </Typography>

                  {/*Roles Update Form*/}
                  <form onSubmit={updateUserRoles}>
                    <FormControl>
                      <FormLabel sx={{mt: 2}} id="roles-group-label">{greekLabels.USER_ROLE}</FormLabel>
                      <RadioGroup
                        aria-labelledby="roles-group-label"
                        name="admin"
                        onChange={handleUserRolesInputChange}
                      >
                        <FormControlLabel value="true" control={<Radio/>} label={greekLabels.PROMOTE_TO_ADMIN}/>
                        <FormControlLabel value="false" control={<Radio/>} label={greekLabels.DEMOTE_TO_USER}/>
                      </RadioGroup>

                      <Button sx={{mt: 2}} size="large" variant="contained" type="submit"
                              endIcon={<CheckIcon/>}>{greekLabels.CHANGE}</Button>
                    </FormControl>
                  </form>

                  {/*Status Alert*/}
                  {roleAlert.isVisible ? <Alert sx={{mt: 5}} variant="filled"
                                                severity={roleAlert.severity}>{roleAlert.message}</Alert> : <></>}
                </CardContent>
              </Card>
            </Box>

            <Box sx={{mb: 3}}>
              {/*User Block Update Card*/}
              <Card>
                <CardContent>
                  <Typography variant="h5">
                    {greekLabels.BLOCKED_USER}
                  </Typography>
                  <Typography variant="body2" sx={{mt: 2}}>
                    {greekLabels.SELECT_USERS_FROM_TABLE}
                  </Typography>
                  <Alert sx={{mt: 2}} severity="warning">
                    {greekLabels.FROZEN_USERS_MSG}  
                  </Alert>

                  {/*User Block Update Form*/}
                  <form onSubmit={updateUsersBlockStatus}>
                    <FormControl>
                      <FormLabel sx={{mt: 2}} id="block-group-label">{greekLabels.USER_TYPE}</FormLabel>
                      <RadioGroup
                        aria-labelledby="block-group-label"
                        name="block"
                        onChange={handleUserBlockInputChange}
                      >
                        <FormControlLabel value="true" control={<Radio/>} label={greekLabels.CONFIRM_BLOCK_USER}/>
                        <FormControlLabel value="false" control={<Radio/>} label={greekLabels.CONFIRM_UNBLOCK_USER}/>
                      </RadioGroup>

                      <Button sx={{mt: 2}} color="error" size="large" variant="contained" type="submit">
                        {greekLabels.CHANGE} <i className="bi bi-slash-circle-fill"></i>
                      </Button>
                    </FormControl>
                  </form>

                  {/*Status Alert*/}
                  {blockAlert.isVisible ? <Alert sx={{mt: 5}} variant="filled"
                                                 severity={blockAlert.severity}>{blockAlert.message}</Alert> : <></>}
                </CardContent>
              </Card>
            </Box>
          </Grid>

        </Grid>
      </Container>

      {dialogIsVisible &&
        <ConfirmationDialogBox title={confirmationDialog.title} description={confirmationDialog.description}
                               setVisibility={setDialogIsVisible} onAgree={confirmationDialog.onAgree}
                               onDecline={confirmationDialog.onDisagree}/>}
    </div>
  );
};

AccountsManager.propTypes = {};
AccountsManager.defaultProps = {};

export default AccountsManager;
