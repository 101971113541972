import React from 'react';
import Modal from './Modal';
import Button from '../FormElements/Button/Button';
import './ErrorModal.css';
import { greekLabels } from '../../../util/language';

const ErrorModal = props => {
  return (
    <Modal
      className="errorModal"
      onCancel={props.onClear}
      header= {greekLabels.PROCESS_PROBLEM}
      show={!!props.error}
      footer={<Button onClick={props.onClear}>{greekLabels.OKAY}</Button>}
    >
      <p>{props.error}</p>
    </Modal>
  );
};

export default ErrorModal;
