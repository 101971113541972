import React from "react";
import "./Developers.css"
import {Col, Container, Row} from "react-grid-system";
import Card from "../CustomUIElements/Card/Card";
import { greekLabels } from "../../util/language";

export function Developers() {
  return <React.Fragment>
    <Container className="developers">
      <h1>{greekLabels.DEVELOPERS} <i className="bi bi-file-person"></i> ({greekLabels.IN_ENGLISH})</h1>
      <h2>Developers</h2>
      <Row>
        <Col sm={12} md={4} className="column">
          <h3>Team 1</h3>
          <Card>
            <span>Antonios Antoniadis</span>
          </Card>
          <Card>
            <span>Evangelos Barmpas</span>
          </Card>
          <a className="link-style" href="https://www.linkedin.com/mwlite/in/lorikkorca">
            <Card>
              <span> Lorik Korca</span>
            </Card>
          </a>
          <Card>
            <span>Rei Manushi </span>
          </Card>
          <Card>
            <span> Vijon Baraku</span>
          </Card>
        </Col>
        <Col sm={12} md={8} className="column link-style">
          <h3>Team 2</h3>
          <a href="https://www.linkedin.com/in/nikos-lintas-computer-scientist/">
            <Card><span>Nikolaos Lintas: Team Leader, Full Stack Engineer, CI, QA</span></Card>
          </a>
          <a href="https://www.linkedin.com/in/phillipos-andrianos-k-968454119/">
            <Card><span>Philippos Andrianos Kalatzis: Full Stack Engineer, Deployment</span></Card>
          </a>
          <a href="https://www.linkedin.com/in/giorgos-tamvakas-524253213/">
            <Card><span>Georgios Tamvakas: Backend Engineer, Deployment</span></Card>
          </a>
          <a href="https://www.linkedin.com/in/aggelogiannos/">
            <Card><span>Ioannis Angelos Chaidemenos: Frontend Engineer, QA</span></Card>
          </a>
        </Col>
        <Col sm={12} md={4} className="column">
          <h3>Team 3</h3>
          <Card>
            <span>Tron Baraku</span>
          </Card>
          <Card>
            <span>Chase Burton Taylor</span>
          </Card>
          <Card>
            <span>Marino Osmanllari</span>
          </Card>
          <Card>
            <span>Yusuf Demirhan</span>
          </Card>
        </Col>
      </Row>
      <h2>Institution</h2>
      <p style={{textAlign: "justify"}}>
        This project is 1 year old, created in cooperation with the Municipality of Thessaloniki.
        Developers and stakeholders collectively thank the University of City College for this opportunity.
      </p>
      <a href="https://invent.york.citycollege.eu/">
        Created as part of Invent, City College 
        <i style={{color: "green", fontSize: "20px"}} className="bi bi-lightbulb"></i>
      </a>
    </Container>
  </React.Fragment>
}
