import React from 'react';
import './ContactUs.css';
import Paper from '@mui/material/Paper';
import {Col, Container, Row} from 'react-grid-system';
import { greekLabels } from '../../util/language';

// Styles
const cardStyles = {
  padding: "10px 10px 10px 0",
  margin: "30px"
}

const cityEmail = `mailto:${process.env.REACT_APP_CITY_EMAIL}`;
const munEmail = `mailto:${process.env.REACT_APP_MUN_EMAIL}`;

const ContactUs = () => (
    <div className={'contact-us main-text'}>
  <Container>
    {/*Information Area*/}
    <h1>{greekLabels.CONTACT_US}  <i className="bi bi-telephone-fill"></i>
    </h1>
    <h2>{greekLabels.WITH_CITY_COLLEGE}</h2>
    <p> </p>
    <p>{greekLabels.TECHNICAL_QUESTIONS_MSG}</p>
    {/* Cards */}
    <Row className='card-container'>
      <Col md={12} lg={6}>
        <Paper elevation={6} style={cardStyles}>
          <ul className='list'>
            <li><i className="bi bi-at"></i> {greekLabels.WEB_PAGE} 
              <a href='https://york.citycollege.eu/'>{greekLabels.CITY_COLLEGE_WEB_PAGE}</a>
            </li>
          </ul>
        </Paper>
      </Col>
      <Col md={12} lg={6}>
        <Paper elevation={6} style={cardStyles}>
          <ul className='list'>
            <li><i className="bi bi-envelope-fill"></i> Email: 
              <a href={cityEmail}></a>{process.env.REACT_APP_CITY_EMAIL}
            </li>
          </ul>
        </Paper>
      </Col>
      <Col md={12} lg={6}>
        <Paper elevation={6} style={cardStyles}>
          <ul className='list'>
            <li><i className="bi bi-geo-alt-fill"></i> {greekLabels.LOCATION} 
              <a href='https://goo.gl/maps/HoWthf2EwRbQKVVu7'>{greekLabels.CITY_COLLEGE_GOOGLE_PLAY}</a>
            </li>
          </ul>
        </Paper>
      </Col>
    </Row>

    {/*Information Area*/}
    <h2 style={{textAlign: "left"}}>{greekLabels.WITH_MUNICIPALITY_OF_THESSALONIKI}</h2>
    <p>{greekLabels.MUNICIPALITY_MSG}</p>
    {/* Cards */}
    <Row className='card-container'>
      <Col md={12} lg={6}>
        <Paper elevation={6} style={cardStyles}>
          <ul className='list'>
            <li><i className="bi bi-at"></i> {greekLabels.WEB_PAGE} 
              <a href='https://thessaloniki.gr/'>{greekLabels.MUNICIPALITY_OF_THESSALONIKI_WEB_PAGE}</a>
            </li>
          </ul>
        </Paper>
      </Col>
      <Col md={12} lg={6}>
        <Paper elevation={6} style={cardStyles}>
          <ul className='list'>
            <li><i className="bi bi-envelope-fill"></i> Email: 
              <a href={munEmail}>{(process.env.REACT_APP_MUN_EMAIL === "email_here") ? "No Email Provided Yet" : process.env.REACT_APP_MUN_EMAIL}</a>
            </li>
          </ul>
        </Paper>
      </Col>
      <Col md={12} lg={6}>
        <Paper elevation={6} style={cardStyles}>
          <ul className='list'>
            <li><i className="bi bi-geo-alt-fill"></i> {greekLabels.LOCATION} 
              <a href='https://goo.gl/maps/jd5HxdFXtUgDdiTEA'>{greekLabels.MUNICIPALITY_GOOGLE_MAPS}</a>
            </li>
          </ul>
        </Paper>
      </Col>
    </Row>
  </Container>
</div>
)
;

ContactUs.propTypes = {};
ContactUs.defaultProps = {};

export default ContactUs;
