import React, {useContext, useEffect, useState} from "react";
import Card from "../../CustomUIElements/Card/Card";
import Button from "../../CustomUIElements/FormElements/Button/Button";
import ErrorModal from "../../CustomUIElements/Modals/ErrorModal";
import LoadingSpinner from "../../CustomUIElements/LoadingSpinner/LoadingSpinner";
import {useHttpClient} from "../../../hooks/http-hook";
import "./ReportItem.css";
import {AuthContext} from "../../../context/auth-context";
import { greekLabels } from "../../../util/language";

const ReportItem = (props) => {
  // State variables & HTTP client
  const {isLoading, error, sendRequest, clearError} = useHttpClient();
  const [loadedTree, setLoadedTree] = useState({title: "", type: "", address: ""});
  const [loadedUser, setLoadedUser] = useState({email: ""});
  const [loadedAdmin, setLoadedAdmin] = useState({email: ""});
  const [adminId] = useState(props.adminId);
  const [isResolved] = useState(props.isResolved);
  const onAssign = props.onAssignReport
  const onResolve = props.onResolveReport
  const auth = useContext(AuthContext);

  // Get report tree
  const fetchTree = async () => {
    try {
      let url = process.env.REACT_APP_BACKEND_URL + `/trees/${props.treeId}/`
      await sendRequest(
        url,
        "GET",
        null,
        {
          "Content-Type": "application/json",
          "Authorization": "JWT " + auth.token
        }
      ).then(responseData => {
        setLoadedTree(responseData.tree);
      })
    } catch (err) {
      console.log("Exception thrown in fetch tree: " + err);
    }
  };

  // Get report user
  const fetchUser = async () => {
    try {
      let url = process.env.REACT_APP_BACKEND_URL + `/users/${props.creatorId}/`
      await sendRequest(
        url,
        "GET",
        null,
        {
          "Content-Type": "application/json",
          "Authorization": "JWT " + auth.token
        }
      ).then(responseData => {
        setLoadedUser(responseData);
      })
    } catch (err) {
      console.log("Exception thrown in fetch user: " + err);
    }
  };

  // Gets the report's admin
  const fetchAdmin = async () => {
    // Check if the value was returned first
    if (adminId) {
      try {
        let url = process.env.REACT_APP_BACKEND_URL + `/users/${adminId}/`
        await sendRequest(
          url,
          "GET",
          null,
          {
            "Content-Type": "application/json",
            "Authorization": "JWT " + auth.token
          }
        ).then(responseData => {
          setLoadedAdmin(responseData);
        })
      } catch (err) {
        console.log("Exception thrown in fetch admin: " + err);
      }
    }
  };

  // Run all backend queries
  useEffect(() => {
    fetchTree();
    fetchUser();
    fetchAdmin();
  }, []);

  // Change status text
  let status;
  if (isResolved) {
    status = greekLabels.RESOLVED
  } else {
    if (adminId) {
      status = greekLabels.ONGOING
    } else {
      status = greekLabels.NOT_RESOLVED
    }
  }

  // Report item UI code
  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError}/>
      <li className='report-item'>
        <Card>
          {isLoading && <LoadingSpinner asOverlay/>}
          <div>
            <p className={"title"}><i className="bi bi-tree"></i> {props.title} </p>
            {loadedTree &&
              <div>
                <p><span>{greekLabels.DESCRIPTION}</span> {props.description}</p>
                <p><span>{greekLabels.NAME_OF_TREE}</span> {loadedTree.name}</p>
                <p><span>{greekLabels.TYPE_OF_TREE}</span> {loadedTree.type}</p>
                <p><span>{greekLabels.AREA_OF_TREE}</span> {loadedTree.address}</p>
              </div>
            }
            {
              loadedUser && <div>
                <p><span>{greekLabels.USER}</span> {loadedUser.email}</p>
              </div>
            }
            {
              loadedAdmin && <div>
                <p><span>{greekLabels.ADMINISTRATOR}</span> {loadedAdmin.email}</p>
              </div>
            }
            {/*Status variable used here for text*/}
            <p><span>{greekLabels.CONDITION}</span> {status} </p>
          </div>
          <hr/>
          <div className='btn-group'>
            {!isResolved &&
              <Button inverse onClick={() => {
                onAssign(props.id)
              }}>
                {!adminId ? greekLabels.I_TAKE_IT : greekLabels.LEAVE_IT} <i className="bi bi-pin-angle"></i>
              </Button>
            }
            {!isResolved &&
              <Button water onClick={() => {
                onResolve(props.id)
              }}>
                {greekLabels.RESOLVED_CAPITAL} <i className="bi bi-check2-circle"></i>
              </Button>
            }
          </div>
        </Card>
      </li>
    </React.Fragment>
  );
};

export default ReportItem;
