const greekLabels = {
  //FRONTEND FILE FOR FRONTEND TRANSLATIONS

  //AccountsManager
  USER_ROLE: "Ρόλοι Χρήστη",
  USERS_ROLES: "Ρόλοι Χρηστών",
  BLOCKED: "Μπλοκαρισμένος",
  COULD_NOT_LOAD_USER_DATA: "Δεν ήταν δυνατή η φόρτωση των δεδομένων χρήστη.",
  INFORMATION_FOR_USER_ROLE: "Ενημέρωση του ρόλου χρήστη",
  UPDATE_SELECTED_USER_ROLES: "Είστε βέβαιοι ότι θέλετε να ενημερώσετε τους επιλεγμένους ρόλους των χρηστών σας;",
  CANNOT_MODIFY_OWN_ROLE: "Δεν μπορείτε να τροποποιήσετε τον δικό σας ρόλο.",
  OPERATION_COMPLETED: "Λειτουργία Ολοκληρώθηκε",
  OPERATION_FAILED: "Λειτουργία Απέτυχε",
  UNBLOCK_USER: "Ξεμπλοκάρισμα χρήστη",
  SURE_FOR_UNBLOCKING_USERS: "Είστε βέβαιοι ότι θέλετε να μπλοκάρετε ή να ξεμπλοκάρετε τους επιλεγμένους χρήστες σας;",
  CANNOT_UNBLOCK_YOURSELF: "Δεν μπορείτε να μπλοκάρετε τον εαυτό σας",
  SEARCH_FOR_USER: "Ψάξε για χρήστη",
  USER_TABLE: "Πίνακας Χρηστών",
  CONVERT_USER_ROLE: "Μετατροπή Ρόλων Χρήστη",
  UPDATE_USER_ROLE_MSG: "Για να ενημερώσετε τους ρόλους χρήστη, επιλέξτε χρήστες από τον πίνακα και τους ρόλους που επιθυμείτε να κατέχουν μετά την μετατροπή.",
  PROMOTE_TO_ADMIN: "Κάνε διαχειριστή",
  DEMOTE_TO_USER: "Οχι πλέον διαχειριστής",
  CHANGE: "ΑΛΛΑΞΕ",
  BLOCKED_USER: "Αποκλεισμός Xρήστη",
  SELECT_USERS_FROM_TABLE: "Επιλέξτε χρήστες από τον πίνακα και επιλέξτε εάν πρέπει να μπλοκαριστούν ή να ξεμπλοκαριστούν.",
  FROZEN_USERS_MSG: "Μόλις αποκλειστούν οι χρήστες, δεν μπορούν να συνδεθούν ξανά. Ο λογαριασμός τους είναι οριστικά παγωμένος μέχρι να διαγραφεί με μη αυτόματο τρόπο.",
  USER_TYPE: "Τύπος Χρήστη",
  CONFIRM_BLOCK_USER: "Μπλόκαρε το χρήστη",
  CONFIRM_UNBLOCK_USER: "Ξεμπλόκαρε το χρήστη",
  SEARCH: "ΨΑΞΕ",

  //AdminPanel
  ADMIN_PANEL: "Πίνακας Διαχειριστή ",

  //Auth
  CODE_NOT_MATCHING: "Ο κωδικός που εισαγάγατε είναι λάθος",
  EMAIL_FOR_PASSWORD_CHANGE: "Θα σας αποσταλεί email για την αλλαγή κωδικού",
  EMPTY_BOX: "Το πλαίσιο είναι κενό", 
  VERIFICATION_CODE_SENT: "Ένας κωδικός επαλήθευσης έχει σταλεί στο email σας", 
  FORGOT_PASSWORD: "Ξεχάσατε τον κωδικό σας;", 
  CONFIRM: "ΕΠΙΒΕΒΑΙΩΣΗ",
  WRITE_YOUR_EMAIL: "Γράψτε το email σας: ",
  CREATE_NEW_ACCOUNT: "Δημιουργία νέου λογαριασμού",
  USE_PROPER_EMAIL: "Παρακαλούμε χρησιμοποιήστε ένα έγκυρο email.",
  PASSWORD: "Κωδικός πρόσβασης",
  USE_PROPER_PASSWORD: "Παρακαλούμε χρησιμοποιήστε έναν έγκυρο κωδικό πρόσβασης με τουλάχιστον οχτώ χαρακτήρες.",
  GO_TO: "Μεταφερθείτε στην",
  CLARIFICATION_PARAGRAPH: "Το email σας το χρησιμοποιούμε μόνο για τη δημιουργία λογαριασμού στην εφαρμογή και για την ενημέρωση σας σχετικά με τις υπηρεσίες που προσφέρουμε.",
  DEFENDED_BY: "Οι χρήστες της ιστο-εφαρμογής μας προστατεύονται από τους",
  GOOGLE_REGULATIONS: "κανονισμούς της Google. ",
  EU_USERS: "Περαιτέρω, οι χρήστες της Ευρωπαϊκής Ένωσης και Ηνωμένων Εθνών της Αμερικής προστατεύονται επιπλέον",
  FROM_THESE_REGULATIONS: "από αυτούς τους νόμους",

  //ContactUs
  WITH_CITY_COLLEGE: "Με CITY College",
  TECHNICAL_QUESTIONS_MSG: "Για τεχνικές ερωτήσεις, αναφορές προβλημάτων με το σύστημά ή άλλες πληροφορίες σχετικά με προσωπικά δεδομένα ή όρους παροχής υπηρεσιών παρακαλώ επικοινωνήστε μαζί μας μέσω μίας από τις παρακάτω μεθόδους:",
  WEB_PAGE: "Ιστοσελίδα:",
  CITY_COLLEGE_WEB_PAGE: "Δείτε εδώ την ιστοσελίδα του CITY College",
  LOCATION: "Τοποθεσία:",
  CITY_COLLEGE_GOOGLE_PLAY: "Δείτε το City College στο Google Maps",
  WITH_MUNICIPALITY_OF_THESSALONIKI: "Με Δήμο Θεσσαλονίκης",
  MUNICIPALITY_MSG: "Ο Δήμος θέλει να ενημερωθεί για το πρόβλημα που βρήκες, μόνο αν συνεργαστούμε μπορούμε να σώσουμε το περιβάλλον! Παρακαλώ επικοινωνήστε μαζί μας μέσω μίας από τις παρακάτω μεθόδους:",
  MUNICIPALITY_OF_THESSALONIKI_WEB_PAGE: "Δείτε εδώ την ιστοσελίδα του Δήμου Θεσσαλονίκης",
  MUNICIPALITY_GOOGLE_MAPS: "Δείτε το δημαρχείο στο Google Maps",

  //ConfirmationDialogBox
  DO_NOT_AGREE: "Δεν Συμφωνώ",
  AGREE: "Συμφωνώ",

  //ErrorModal
  PROCESS_PROBLEM: "Υπήρξε ένα πρόβλημα κατά την διαδικασία!",

  //Developers
  IN_ENGLISH: "Στα Αγγλικά",

  //LandingPage
  PROTECT_BY_ADOPTING: "Εδώ, μπορείς να συμμετάσχεις στην παγκόσμια προσπάθεια προστασίας του περιβάλλοντος υιοθετώντας δέντρα στη Θεσσαλονίκη από την άνεση του σπιτιού σου!",
  
  //LPCallToAction
  SIGN_IN_NOW: "Συνδεθείτε τώρα για να ξεκινήσετε την περιήγηση της ιστο-εφαρμογής!",
  LOGIN_CAPITAL: "ΣΥΝΔΕΣΗ",

  //Legal
  LEGALITY_DATA: "Δεδομένα Νομιμότητας ",
  PRIVACY_POLICY: "Πολιτική Απορρήτου και 'Οροι Παροχής Υπηρεσιών (Στα Αγγλικά)",
  LEGALITY_PARAGRAPH: "Η ιστο-εφαρμογή του Δήμου Θεσσαλονίκης εγγυάται ασφάλεια στους χρήστες της συμμορφώνοντας την πλατφόρμα με παγκόσμια αναγνωρισμένους όρους και προϋποθέσεις παροχής υπηρεσιών της Ευρωπαϊκής Ένωσης, εξωτερικών οργανισμών και υπηρεσιών (όπως Google). Οι πολιτικές και όροι χρήσης παρέχονται μόνο στα αγγλικά προς το παρόν λόγω κανονισμών εξωτερικών υπηρεσιών της ιστο-εφαρμογής.",
  SELECT_ICON_FOR_MORE_INFO: "Επιλέξτε ένα από τα εικονίδια για να μεταφερθείτε στο εξωτερικό έγγραφο που αναφέρει τις παραπάνω πληροφορίες.",

  //MapDeckGl
  WANT_TO_ADOPT_TREE: "Θέλετε να υιοθετήσετε αυτό το δέντρο;",
  ADOPTION_BIG_RESPONSIBILITY: " Η υιοθεσία είναι μεγάλη ευθύνη!",
  TREE_HAS_OWNER: "Αυτό το δέντρο έχει ήδη ιδιοκτήτη!",
  SELECT_ANOTHER: "Παρακαλούμε επιλέξτε ένα άλλο δέντρο!",
  YOUR_TREE: "Αυτό το δέντρο σας ανήκει!",
  TO_ADOPT_SELECT_ANOTHER: "Αν θέλετε να υιοθετήσετε, παρακαλούμε επιλέξτε ένα άλλο δέντρο!",
  YOUNG_TREE: "Αυτό το δέντρο είναι ακόμα νέο, θέλετε να το υιοθετήσετε;",
  YOUNG_TREE_REQUIRES_WATERING: "Αυτό το δέντρο είναι μπλε γιατί είναι ακόμα νέο. Τα νέα δέντρα απαιτούν περισσότερο πότισμα. Είστε σίγουροι πως θέλετε να το υιοθετήσετε;",
  ADOPT_THIS_TREE: "Υιοθέτηση δέντρου",
  LOADING_TREES: "Ο χάρτης με τα δέντρα φορτώνει 🌳",
  ADOPT_IT: "ΥΙΟΘΕΤΗΣΤΕ ΤΟ!",
  CONGRATULATIONS: "Συγχαρητήρια! Υιοθετήσατε ένα δέντρο!",
  SEE_OR_ADOPT: "Θέλετε να δείτε τα δέντρα σας ή να υιοθετήσετε ακόμα ένα δέντρο;",
  SEE_YOUR_TREES: "ΔΕΙΤΕ ΤΑ ΔΕΝΤΡΑ ΣΑΣ",
  ADOPT_ANOTHER_ONE: "ΥΙΟΘΕΤΗΣΤΕ ΑΚΟΜΑ ΕΝΑ",
  ADOPTION_FAILED: "Η υιοθεσία απέτυχε. ",
  WANT_TO_SEE_YOUR_TREES: "Θέλετε να δείτε τα δέντρα σας;",
  CLOSE: "ΚΛΕΙΣΕ",
  WRONG_POSTAL_CODE: "Ο ταχυδρομικός κώδ. δε βρέθηκε",
  TRY_AGAIN: "Παρακαλώ προσπάθησε ξανα!",
  OKAY: "Εντάξει",
  SHOW_ME: "Δείξε μου...",
  ALL_TREES: "Όλα τα δέντρα",
  ADOPTED_TREES: "Υιοθετημένα Δέντρα",
  AVAILABLE_TREES: "Διαθέσιμα Δέντρα",
  CANCEL_LOWER: "Ακύρωση",
  GO_THERE: "Πηγαινε εκει",
  NOT_AVAILABLE_TREES: "Μη Διαθέσιμα Δέντρα",
  FILTER: "Φιλτράρισμα",
  ZOOM_POSTAL: "Εύρεση TK",
  ZOOM_TO_ALL_TREES: "Εστίαση στα δέντρα σας",
  CANCEL_MSG: "ΑΚΥΡΩΣΗ",
  DELETE_IT: "Αφαιρεση απο τα δεντρα μου",

  //NavLinks
  MY_TREES_LOWER: "Τα δέντρα μου ",
  REPORTS: "Αναφορές ",
  USER_MANAGEMENT: "Διαχείριση χρηστών ",
  TREE_MAP: "Χάρτης δέντρων",
  LOGIN: "Σύνδεση ",
  LEGALITY: "Νομιμότητα",
  CONTACT_US: "Επικοινωνία",
  DEVELOPERS: "Δημιουργοί",
  SETTINGS: "Ρυθμίσεις ",

  //ReportItem
  RESOLVED: "Επιλύθηκε",
  ONGOING: "Σε εξέλιξη",
  NOT_RESOLVED: "Δεν επιλύθηκε",
  DESCRIPTION: "Περιγραφή:",
  NAME_OF_TREE: "Όνομα Δέντρου:",
  TYPE_OF_TREE: "Τύπος Δέντρου:",
  AREA_OF_TREE: "Περιοχή Δέντρου:",
  USER: "Χρήστης:",
  ADMINISTRATOR: "Διαχειριστής:",
  CONDITION: "Κατάσταση:",
  RESOLVED_CAPITAL: "ΕΠΙΛΥΘΗΚΕ",
  I_TAKE_IT: "ΤΟ ΑΝΑΛΑΜΒΑΝΩ",
  LEAVE_IT: "ΠΑΡΑΤΑ ΤΟ",

  //ReportList
  NO_USER_REPORTS: "Δεν υπάρχουν αναφορές από χρήστες",

  //UserReports
  REPORT_STATUS_UPDATED: "Κατάσταση αναφοράς ενημερώθηκε!",
  NO_REPORT_STATUS_CHANGE: "Δεν έγινε αλλαγή κατάστασης αναφοράς...",
  REPORT_RESOLVED: "Αναφορά επιλύθηκε!",
  NO_REPORT_RESOLUTION_CHANGE: "Δεν έγινε αλλαγή επίλυσης αναφοράς...",
  TREE_REFERENCE_TABLE: "Πίνακας Αναφορών Δέντρων",

  //RestPassword
  SUCCESSFUL_PASSWORD_CHANGE: "Ο κωδικός άλλαξε με επιτυχία!",
  EIGHT_CHARACTERS_PASSWORD: "Ο κωδικός πρέπει να είναι πάνω από 8 χαρακτήρες",
  NOT_SAME_PASSWORD: "Οι κωδικοί που πληκτρολογήσατε δεν είναι ίδιοι",
  CHANGE_PASSWORD: "Αλλαγή Κωδικού",
  WRITE_NEW_PASSWORD: "Γράψτε τον νέο κωδικό: ",
  CONFIRM_NEW_PASSWORD: "Επιβεβαιώστε τον κωδικό: ",
  CONFIRM_PASSWORD: "ΕΠΙΒΕΒΑΙΩΣΗ",

  //Settings
  RECEIVE_EMAIL_FOR_PASSWORD_RESET: "Θα σας αποσταλεί email για την αλλαγή κωδικού",
  SUCCESSFUL_EMAIL_SENT: "To Email σας έχει αλλάξει με επιτυχία!",
  USE_VALID_EMAIL: "Χρησιμοποιήστε έγκυρο email",
  EMPTY_INPUT_BOX: "Το πλαίσιο είναι κενό",
  ACCOUNT_SETTINGS: "Ρυθμίσεις Λογαριασμού",
  CHANGE_EMAIL: "Αλλαγή Email",
  CONFIRM_CHANGE: "ΑΛΛΑΓΗ",
  RESET_PASSWORD: "Επαναφορά Κωδικού",
  CONFIRM_PASSWORD_RESET: "ΕΠΑΝΑΦΟΡΑ",
  DELETE_ACCOUNT: "Διαγραφή Λογαριασμού",
  CONFIRM_DELETE: "ΔΙΑΓΡΑΦΗ",
  DISCONNECT: "Αποσύνδεση",
  DELETE_ACCOUNT_QUESTION: "Είστε σίγουρος ότι θέλετε να διαγράψετε τον λογαριασμό σας;",
  PASSWORD_CHANGE_QUESTION: "Είστε σίγουρος ότι θέλετε να επαναφέρετε τον κωδικό σας;",
  WRITE_NEW_EMAIL: "Γράψτε το νέο σας Email:",
  GLOBAL_FILTER: "Αποθήκευση της επιλογής φιλτραρίσματος των δέντρων",
  
  //TreeItem
  TODAY: "ΣΗΜΕΡΑ",
  YESTERDAY: "ΧΘΕΣ",
  DAYS_BEFORE: " ΜΕΡΕΣ ΠΡΙΝ",
  NOT_WATERED: "ΔΕΝ ΕΧΕΙ ΠΟΤΙΣΤΕΙ",
  EMPTY_TITLE: "Έχεις αφήσει τον τίτλο κενό.",
  EMPTY_DESCRIPTION: "Έχεις αφήσει την περιγραφή κενή.",
  TYPE: "ΕΙΔΟΣ: ",
  LAST_WATERED: "ΤΕΛΕΥΤΑΙΟ ΠΟΤΙΣΜΑ: ",
  AGE: "ΗΛΙΚΙΑ: ",
  WEEKS: " ΕΒΔΟΜΑΔΕΣ",
  SEE_ON_MAP: "ΔΕΙΤΕ ΤΟ ΣΤΟΝ ΧΑΡΤΗ ",
  
  //TreeList
  NO_ADOPTIONS_INFO: "Δε βρέθηκαν υιοθετημένα δέντρα, Μήπως θέλετε να υιοθετήσετε ένα;",

  //TreeDeleteModal
  DELETE_FROM_MY_TREES: "ΔΙΑΓΡΑΦΗ ΑΠΟ ΤΑ ΔΕΝΤΡΑ ΜΟΥ",
  DELETE_TREE_QUESTION: "Θέλετε να διαγράψετε αυτό το δέντρο από τα δέντρα σας;",

  //TreeRenameModal
  RENAME_TREE: "Μετονομάσια δεντρου",
  NEW_NICKNAME: "Εισαγάγετε το νέο όνομα δέντρου: ",
  INSERT_NICKNAME: "Εισαγάγετε το νέο όνομα",

  //TreeReportModal
  REPORT_TREE: "Αναφορά Δέντρου",
  REPORT_METHOD: "ΑΝΑΦΟΡΑ ΠΡΟΒΛΗΜΑΤΟΣ",
  SELECT_CATEGORY: "Διαλέξτε μία από τις κατηγορίες για το δέντρο σας:",
  SICK_TREE: "Eχει κάποια αρρώστια",
  UNHEALTHY_TREE: " Φαίνεται ανθυγιεινό",
  CANT_WATER_TREE: " Δεν μπορώ να το ποτίσω",
  DIRTY_TREE: "Eχει πολλά σκουπίδια",
  WRITE_TITLE: "Γράψτε έναν τίτλο",
  OTHER: "Άλλο",
  DESCRIBE_PROBLEM: "Περιγράψτε το πρόβλημα",

  //TreeWaterModal
  ARE_YOU_SURE_MSG: "Είστε σίγουροι;",
  WATER_TREE: "ΠΟΤΙΣΤΕ",
  WATER_QUESTION: "Ποτίσατε αυτό το δέντρο;",
  WATERED_RECENTLY: "Έχετε ήδη ποτίσει αυτό το δέντρο πρόσφατα. Είστε σίγουροι ότι το ποτίσατε ξανά;",

  //UserTrees
  REPORT_SENT_MSG: "Αναφορά στάλθηκε με επιτυχία!",
  MY_TREES: "Τα Δέντρα Μου",
  ADOPT_A_TREE: "Υιοθετήστε ένα δέντρο",
  WATER_ALL_TREES: "Ποτίστε όλα τα δέντρα",

  //Verify
  UNMATCHING_VERIFICATION_CODE: "Ο κωδικός επαλήθευσης που πληκτρολογήσατε είναι λάθος",
  VERIFICATION_MSG: "Πληκτρολογήστε τον κωδικό επαλήθευσης",
  SUBMIT_MSG: "Υποβολή"
};

exports.greekLabels = greekLabels;
